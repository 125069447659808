<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <meta content="" name="description">
  <meta content="" name="keywords">

  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css" />

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
    rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">

  <!-- Template Main CSS File -->
  <link href="/assets/css/style.css" rel="stylesheet">

</head>

<body>
  <!--Main layout-->
  <main style="margin-top: 110px;">
    <div class="container pt-4"></div>
  </main>
  <!--Main layout-->

  <section id="blog" class="blog" style="margin-top: 50px;">
    <div class="section-title" data-aos="fade-up">
      <h4>Cargar documento:</h4>
      <div class="fondo" data-aos="fade-up">
        <div style="justify-content:center; text-align:center;">
          <form [formGroup]="documentoForm">



            <label style="margin-top: 2%; margin-right: 1%; font-weight: bold; font-weight: bold;">Seleccione la sección: </label>
            <select formControlName="SECCION" style="border-radius: 1vh;">
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
              <option value="13">13</option>
              <option value="14">14</option>
              <option value="15">15</option>
              <option value="16">16</option>
              <option value="17">17</option>
              <option value="18">18</option>
              <option value="19">19</option>
              <option value="20">20</option>
              <option value="21">21</option>
              <option value="22">22</option>
              <option value="23">23</option>
              <option value="24">24</option>
              <option value="25">25</option>
              <option value="26">26</option>
              <option value="27">27</option>
              <option value="28">28</option>
              <option value="29">29</option>
              <option value="30">30</option>
              <option value="31">31</option>
              <option value="32">32</option>
              <option value="33">33</option>
              <option value="34">34</option>
              <option value="35">35</option>
              <option value="36">36</option>
              <option value="37">37</option>
              <option value="38">38</option>
              <option value="39">39</option>
              <option value="40">40</option>
              <option value="41">41</option>
              <option value="42">42</option>
              <option value="43">43</option>
              <option value="44">44</option>
              <option value="45">45</option>

            </select>

            <br>

            <label style="margin-top: 2%; margin-right: 1%; font-weight: bold; font-weight: bold;">Seleccione la carpeta: </label>
            <select formControlName="CARPETA" style="border-radius: 1vh;">


              <optgroup label="Sección 1">
                <option value="24">2022</option>
                <option value="25">2023</option>
                <option value="26">2024</option>
              </optgroup>

              <optgroup label="Sección 8 - 9">
                <option value="19">Obras Públicas</option>
                <option value="20">Unidad Centralizada de Compras</option>

              </optgroup>




              <optgroup label="Sección 23">
                <option value="13">Los resultados de la dictaminacion de los estados financieros.</option>
                <option value="14">Deuda Pública</option>
                <option value="15"> Resultados de las auditorias internas y/o externas al ejercicio prosupuestal.</option>
                <option value="16">Contratos colectivos sindicales y/o de beneficio para los trabajadores.</option>
              </optgroup>

              <optgroup label="Sección 16">
                <option value="11">Compras</option>
                <option value="12">Listado General del parque Vehícular</option>

              </optgroup>


              <optgroup label="Sección 22">
                <option value="1">Donativos en Especie</option>
                <option value="2">Donativos Económicos</option>
                <option value="3">Comodatos</option>
              </optgroup>

              <optgroup label="Sección 23">
                <option value="8">Listado de licencias de construcción por mandato judicial.</option>
                <option value="9">Listado de permisos de giros comerciales por mandato judicial.</option>
                <option value="10">Listado de resolutivos de laudos laborales emitidos por mandato judicial.</option>
              </optgroup>


              <optgroup label="Sección 29 - 35">
                <option value="21">Consejo Municipal del Medio Ambiente</option>
                <option value="22">Consejo Técnico Catastral</option>
                <option value="23">Consejo de Vialidad y Transporte</option>
              </optgroup>




              <optgroup label="Sección 38">
                <option value="17">Informe de resultados anual de cada comisión.</option>

              </optgroup>

              <optgroup label="Sección 41">
                <option value="18">Currículums</option>

              </optgroup>

              <optgroup label="Sección 42">
                <option value="4">Desarrollo Urbano</option>
                <option value="5">Tránsito</option>
                <option value="6">Catastro</option>
                <option value="7">Secretaria General</option>
              </optgroup>

            </select>

            <br><label style="margin-top: 2%; margin-right: 1%; font-weight: bold; font-weight: bold;">Seleccione el
              documento a subir</label>
            <br><input type="file" style="margin-top: 1%;" (change)="contarArchivos($event)" formControlName="ARCHIVO"
              multiple>

            <i class="bi bi-check-circle-fill" style="font-size: 30px; color: #00B59C;"
              *ngIf="!subiendoDocumento && urlDocumento !== '' "></i>

            <div class="spinner-border text-primary" role="status" *ngIf="subiendoDocumento"></div>

          </form>
        </div>
      </div>
    </div>

  </section>


  <!-- <qa href="#" class="back-to-top d-flex align-items-center justify-content-center"><i
      class="bi bi-arrow-up-short"></i></option> -->

  <!-- Vendor JS Files -->
  <script src="/assets/vendor/aos/aos.js"></script>
  <script src="/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="/assets/vendor/glightbox/js/glightbox.min.js"></script>
  <script src="/assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
  <script src="/assets/vendor/swiper/swiper-bundle.min.js"></script>
  <script src="/assets/vendor/waypoints/noframework.waypoints.js"></script>
  <script src="/assets/vendor/php-email-form/validate.js"></script>

  <!-- Template Main JS File -->
  <script src="/assets/js/main.js"></script>

</body>
