
<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <title>SAION - Tecnología gubernamental</title>
  <meta content="" name="description">
  <meta content="" name="keywords">

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i" rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">

  <!-- Template Main CSS File -->
  <link href="/assets/css/style.css" rel="stylesheet">

  <!-- =======================================================
  * Template Name: Company - v4.7.0
  * Template URL: https://bootstrapmade.com/company-free-html-bootstrap-template/
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  ======================================================== -->
</head>










<div class="login" style="margin-top: 250px;">
  <div class="form">
    <form [formGroup]="loginForm" (ngSubmit)="onLogin(loginForm.value)">
    <h2></h2>
 <h4 style="text-align: center;color: #ffffff;">Iniciar Sesión</h4>
    <input style="background-color: #ffffff; color: #000000;"  placeholder="Usuario" formControlName="usuario">
    <input style="background-color: #ffffff; color: #000000;" id="contrasena" placeholder="Contraseña" type="password"  formControlName="contrasena">
    <input style="background-color: #000000; color: #ffffff;" type="submit" value="Aceptar" class="submit">
    </form>
    <div class="text-center mt-5 text-muted;" style="color: #ffffff;">
      Copyright &copy; 2021-2024 &mdash; AUTLAN
    </div>

    <div class="row" style="justify-content: center; width: 100%;">

      <div class="col-12" >
    <label  style="color: #ffffff; margin-left: 38%; margin-right: 30%;" *ngIf="this.login === false">Revise los datos o su conexión</label>
  </div>
  <div class="col-12" >
    <div *ngIf="iniciandoSesion === true" class="spinner"></div>
  </div>
    </div>

  </div>
</div>


