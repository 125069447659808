import { Component } from '@angular/core';
import { GeneralService } from 'src/services/general.service';
import { SweetAlertService } from 'src/services/sweet-alert.service';
import { Storage, ref, uploadBytes, getDownloadURL } from '@angular/fire/storage';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-comunicacion',
  templateUrl: './comunicacion.component.html',
  styleUrls: ['./comunicacion.component.scss']
})
export class ComunicacionComponent {

  docs: any;
  docs18: any;
  docs19: any;
  docs20: any;

  docs21: any;
  docs22: any;
  docsSubsidio: any;
  boton: any;
  id: any;
docs23:any
docs24:any
  borrar: string[] = [];
  valor: any;
  todo: any;
  todo18: any;
  todo19: any;
  todo20: any;
  todo21: any;
  todo22: any;
  todo24: any;
  todo23: any;
eliminarForm: any = {
  ESTATUS: 0,
  NOMBRE: undefined,
  ENLACE: undefined,

};


clima:any;
urlDocumento:any;
urlDocumento1:any;
urlDocumento2:any;

token:any
subiendoDocumento:any
subiendoDocumento1:any
subiendoDocumento2:any

imag1 = new FormGroup({
  ID: new FormControl(''),
  URL: new FormControl('', Validators.required),
 });
 imag2 = new FormGroup({
  ID: new FormControl(''),
  URL: new FormControl('', Validators.required),

 });

 imag3 = new FormGroup({
  ID: new FormControl(''),
  URL: new FormControl('', Validators.required),

 });


  constructor(private service: GeneralService,private alerts: SweetAlertService,private storage: Storage) { }

  ngOnInit(): void {



    if (localStorage.getItem('token')) {
      this.boton = 1;
      this.token=true
    }


    this.service.VerImg().subscribe(res => {
      this.clima = res.body
      console.log(this.clima);

    })


    this.service.listaCimtra(1).subscribe(res => {
      this.docs = res.body
      console.log(this.docs);

    })
  }

  checkCheckBoxvalue18(event: any, index: any, id: any) {
    console.log(event.target.checked)
    this.todo18 = null;
    this.valor = event.target.checked;
    if (this.valor === true) {
      console.log('verdadero');
      console.log(index);
      this.borrar[index] = this.docs18[index].ID;
      console.log(this.borrar);

    } else if (this.valor === false) {
      console.log('falso');
      console.log(index);
      let indice = this.borrar.indexOf(id);
      this.borrar.splice(indice, 1);
      console.log(this.borrar);

    }
  }

  seleccionarTodo18() {
    this.todo18 = true;
    let valores = this.docs18.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar[i] = this.docs18[i].ID;
      console.log(this.borrar);

    }

  }

  quitarSeleccion18() {
    let valores = this.borrar.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar.pop();
      console.log(this.borrar);
      this.todo18 = false;
    }
  }

  checkCheckBoxvalue19(event: any, index: any, id: any) {
    console.log(event.target.checked)
    this.todo19 = null;
    this.valor = event.target.checked;
    if (this.valor === true) {
      console.log('verdadero');
      console.log(index);
      this.borrar[index] = this.docs19[index].ID;
      console.log(this.borrar);

    } else if (this.valor === false) {
      console.log('falso');
      console.log(index);
      let indice = this.borrar.indexOf(id);
      this.borrar.splice(indice, 1);
      console.log(this.borrar);

    }
  }

  seleccionarTodo19() {
    this.todo19 = true;
    let valores = this.docs19.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar[i] = this.docs19[i].ID;
      console.log(this.borrar);

    }

  }
  checkCheckBoxvalue(event: any, index: any, id: any) {
    console.log(event.target.checked)
    this.todo = null;
    this.valor = event.target.checked;
    if (this.valor === true) {
      console.log('verdadero');
      console.log(index);
      this.borrar[index] = this.docs[index].ID;
      console.log(this.borrar);

    } else if (this.valor === false) {
      console.log('falso');
      console.log(index);
      let indice = this.borrar.indexOf(id);
      this.borrar.splice(indice, 1);
      console.log(this.borrar);

    }
  }

  seleccionarTodo() {
    this.todo = true;
    let valores = this.docs.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar[i] = this.docs[i].ID;
      console.log(this.borrar);

    }

  }
  quitarSeleccion() {
    let valores = this.borrar.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar.pop();
      console.log(this.borrar);
      this.todo = false;
    }
  }
  quitarSeleccion19() {
    let valores = this.borrar.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar.pop();
      console.log(this.borrar);
      this.todo19 = false;
    }
  }

  checkCheckBoxvalue20(event: any, index: any, id: any) {
    console.log(event.target.checked)
    this.todo20 = null;
    this.valor = event.target.checked;
    if (this.valor === true) {
      console.log('verdadero');
      console.log(index);
      this.borrar[index] = this.docs20[index].ID;
      console.log(this.borrar);

    } else if (this.valor === false) {
      console.log('falso');
      console.log(index);
      let indice = this.borrar.indexOf(id);
      this.borrar.splice(indice, 1);
      console.log(this.borrar);

    }
  }

  seleccionarTodo20() {
    this.todo20 = true;
    let valores = this.docs20.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar[i] = this.docs20[i].ID;
      console.log(this.borrar);

    }

  }

  quitarSeleccion20() {
    let valores = this.borrar.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar.pop();
      console.log(this.borrar);
      this.todo20 = false;
    }
  }
  checkCheckBoxvalue21(event: any, index: any, id: any) {
    console.log(event.target.checked)
    this.todo21 = null;
    this.valor = event.target.checked;
    if (this.valor === true) {
      console.log('verdadero');
      console.log(index);
      this.borrar[index] = this.docs21[index].ID;
      console.log(this.borrar);

    } else if (this.valor === false) {
      console.log('falso');
      console.log(index);
      let indice = this.borrar.indexOf(id);
      this.borrar.splice(indice, 1);
      console.log(this.borrar);

    }
  }

  seleccionarTodo21() {
    this.todo21 = true;
    let valores = this.docs21.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar[i] = this.docs21[i].ID;
      console.log(this.borrar);

    }

  }

  quitarSeleccion21() {
    let valores = this.borrar.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar.pop();
      console.log(this.borrar);
      this.todo21 = false;
    }
  }
  checkCheckBoxvalue22(event: any, index: any, id: any) {
    console.log(event.target.checked)
    this.todo22 = null;
    this.valor = event.target.checked;
    if (this.valor === true) {
      console.log('verdadero');
      console.log(index);
      this.borrar[index] = this.docs22[index].ID;
      console.log(this.borrar);

    } else if (this.valor === false) {
      console.log('falso');
      console.log(index);
      let indice = this.borrar.indexOf(id);
      this.borrar.splice(indice, 1);
      console.log(this.borrar);

    }
  }

  seleccionarTodo22() {
    this.todo22 = true;
    let valores = this.docs22.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar[i] = this.docs22[i].ID;
      console.log(this.borrar);

    }

  }
  quitarSeleccion22() {
    let valores = this.borrar.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar.pop();
      console.log(this.borrar);
      this.todo22 = false;
    }
  }

  checkCheckBoxvalue23(event: any, index: any, id: any) {
    console.log(event.target.checked)
    this.todo23 = null;
    this.valor = event.target.checked;
    if (this.valor === true) {
      console.log('verdadero');
      console.log(index);
      this.borrar[index] = this.docs23[index].ID;
      console.log(this.borrar);

    } else if (this.valor === false) {
      console.log('falso');
      console.log(index);
      let indice = this.borrar.indexOf(id);
      this.borrar.splice(indice, 1);
      console.log(this.borrar);

    }
  }

  seleccionarTodo23() {
    this.todo23 = true;
    let valores = this.docs23.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar[i] = this.docs23[i].ID;
      console.log(this.borrar);

    }

  }
  quitarSeleccion23() {
    let valores = this.borrar.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar.pop();
      console.log(this.borrar);
      this.todo23 = false;
    }
  }


  checkCheckBoxvalue24(event: any, index: any, id: any) {
    console.log(event.target.checked)
    this.todo24 = null;
    this.valor = event.target.checked;
    if (this.valor === true) {
      console.log('verdadero');
      console.log(index);
      this.borrar[index] = this.docs24[index].ID;
      console.log(this.borrar);

    } else if (this.valor === false) {
      console.log('falso');
      console.log(index);
      let indice = this.borrar.indexOf(id);
      this.borrar.splice(indice, 1);
      console.log(this.borrar);

    }
  }

  seleccionarTodo24() {
    this.todo24 = true;
    let valores = this.docs24.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar[i] = this.docs24[i].ID;
      console.log(this.borrar);

    }

  }
  quitarSeleccion24() {
    let valores = this.borrar.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar.pop();
      console.log(this.borrar);
      this.todo24 = false;
    }
  }
  eliminarSeleccionados() {

    this.alerts.alertaConfirmacionEliminar('Eliminar documento', '¿Desea eliminar este documento?')
      .then((res: any) => {

        if (res.isConfirmed) {
          for (let numero of this.borrar) {
            console.log(numero);
            this.eliminarForm.ID = numero;
            this.eliminarForm.ESTATUS = 1;
            this.service.eliminarEnlace(this.eliminarForm).subscribe(
              (data: any) => {

              })
            this.alerts.alertaRealizado().then((res: any) => {
              location.reload()
            })

          }

        }

      })
  }

  eliminar(ID: any) {

    this.alerts.alertaConfirmacionEliminar('Eliminar documento', '¿Desea eliminar este documento?')
      .then((res: any) => {

        if (res.isConfirmed) {
          this.eliminarForm.ID = ID;
          this.eliminarForm.ESTATUS = 1;
          this.service.eliminarEnlace(this.eliminarForm).subscribe(
            (data: any) => {


              console.log(ID);

            })
          this.alerts.alertaRealizado().then((res: any) => {
            location.reload()
          })
        }

      })


  }


funcion1(){
  this.service.listaCimtraCarpeta(1,24).subscribe(res => {
    this.docs19 = res.body
    console.log(this.docs);

  })
}
funcion2(){
  this.service.listaCimtraCarpeta(1,25).subscribe(res => {
    this.docs20 = res.body
    console.log(this.docs);

  })
}

funcion3(){
  this.service.listaCimtraCarpeta(1,26).subscribe(res => {
    this.docs21 = res.body
    console.log(this.docs);

  })
}



img1() {
  console.log('entra');
this.imag1.value.ID='1'
console.log(this. imag1.value);

  this.service.editarImg(this.imag1.value).subscribe(res => {
    console.log(res.body);
    console.log('Editado');

    this.service.VerImg().subscribe(res => {
      this.clima = res.body
      console.log(this.clima);
      this.subiendoDocumento=false
    })

  })

}

img2() {
  console.log('entra');
this.imag2.value.ID='2'
console.log(this. imag2.value);

  this.service.editarImg(this.imag2.value).subscribe(res => {
    console.log(res.body);
    console.log('Editado');

    this.service.VerImg().subscribe(res => {
      this.clima = res.body
      console.log(this.clima);
      this.subiendoDocumento1=false
    })

  })

}

img3() {
  console.log('entra');
this.imag3.value.ID='3'
console.log(this.imag3.value);

  this.service.editarImg(this.imag3.value).subscribe(res => {
    console.log(res.body);
    console.log('Editado');

    this.service.VerImg().subscribe(res => {
      this.clima = res.body
      console.log(this.clima);
this.subiendoDocumento2=false
    })

  })

}




async changeFileMenu(event: any) {

  let file = event.target.files[0];

  //TODO: Obtenemos nombre identificador para el documento
  this.getIdentificadorDocumentos.then(res => {
    let identificador = res;
    this.subiendoDocumento = true; //Indicamos que se comienza proceso subir foto
    //TODO: Subimos archivo a Firebase


    const name = '- Autlan';

    let path = 'Noticias';  //Construimos ruta
    let fileRef = ref(this.storage, path + '/' + identificador + name); //Creamos una referncia al archivo usando la ruta

    //Subimos el archivo a Firebase
    uploadBytes(fileRef, file).then(response => {
      //TODO: Descargamos la URL del archivo
      getDownloadURL(fileRef).then(file => {
        this.urlDocumento = file; //guardamos la url del archivo en una variable
        this.subiendoDocumento = false;
        console.log(file);

        this.imag1.value.URL = file;
        console.log(this.imag1.value.URL);

      }).catch(error => { console.log(error) });

    })
  })

}
  //** Promesa para obtener una clave de identificacion de documentos para subirlos a Firebase*/
  getIdentificadorDocumentos = new Promise((resolve, reject) => {
    this.service.obtenerIdentificadorDocumentos(1).subscribe(res => {
      resolve(res.body);  //Cuando se reciben los datos del servidor, resolvemos la promesa
    })
  })

async changeFileMenu1(event: any) {

  let file = event.target.files[0];

  //TODO: Obtenemos nombre identificador para el documento
  this.getIdentificadorDocumentos.then(res => {
    let identificador = res;
    this.subiendoDocumento1 = true; //Indicamos que se comienza proceso subir foto
    //TODO: Subimos archivo a Firebase


    const name = '- Autlan';

    let path = 'Noticias';  //Construimos ruta
    let fileRef = ref(this.storage, path + '/' + identificador + name); //Creamos una referncia al archivo usando la ruta

    //Subimos el archivo a Firebase
    uploadBytes(fileRef, file).then(response => {
      //TODO: Descargamos la URL del archivo
      getDownloadURL(fileRef).then(file => {
        this.urlDocumento1 = file; //guardamos la url del archivo en una variable
        this.subiendoDocumento1 = false;
        console.log(file);

        this.imag2.value.URL = file;
        console.log(this.imag2.value.URL);

      }).catch(error => { console.log(error) });

    })
  })

}




async changeFileMenu2(event: any) {

  let file = event.target.files[0];

  //TODO: Obtenemos nombre identificador para el documento
  this.getIdentificadorDocumentos.then(res => {
    let identificador = res;
    this.subiendoDocumento2 = true; //Indicamos que se comienza proceso subir foto
    //TODO: Subimos archivo a Firebase


    const name = '- Autlan';

    let path = 'Noticias';  //Construimos ruta
    let fileRef = ref(this.storage, path + '/' + identificador + name); //Creamos una referncia al archivo usando la ruta

    //Subimos el archivo a Firebase
    uploadBytes(fileRef, file).then(response => {
      //TODO: Descargamos la URL del archivo
      getDownloadURL(fileRef).then(file => {
        this.urlDocumento2 = file; //guardamos la url del archivo en una variable
        this.subiendoDocumento2 = false;
        console.log(file);

        this.imag3.value.URL = file;
        console.log(this.imag3.value.URL);

      }).catch(error => { console.log(error) });

    })
  })

}

}




