import { Component } from '@angular/core';
import { GeneralService } from 'src/services/general.service';
import { SweetAlertService } from 'src/services/sweet-alert.service';

@Component({
  selector: 'app-sesiones',
  templateUrl: './sesiones.component.html',
  styleUrls: ['./sesiones.component.scss']
})
export class SesionesComponent {

  docs: any;
  docs18: any;
  docs19: any;
  docs20: any;
  docs21: any;
  docs22: any;
  docsSubsidio: any;
  boton: any;
  id: any;

  docs23: any;
  area: any;
  displayStyleEditarNombre = "none";
  borrar: string[] = [];
  valor: any;
  todo18: any;
  todo19: any;
  todo20: any;
  todo21: any;
  todo22: any;
  todo23: any;
  todo: any;

eliminarForm: any = {
  ESTATUS: 0,
  NOMBRE: undefined,
  ENLACE: undefined,

};
  constructor(private service: GeneralService,private alerts: SweetAlertService) { }

  ngOnInit(): void {

    if (localStorage.getItem('token')) {
      this.boton = 1;
    }


    this.service.listaCimtra(35).subscribe(res => {
      this.docs = res.body
      console.log(this.docs);

    })
  }
  checkCheckBoxvalue18(event: any, index: any, id: any) {
    console.log(event.target.checked)
    this.todo18 = null;
    this.valor = event.target.checked;
    if (this.valor === true) {
      console.log('verdadero');
      console.log(index);
      this.borrar[index] = this.docs18[index].ID;
      console.log(this.borrar);

    } else if (this.valor === false) {
      console.log('falso');
      console.log(index);
      let indice = this.borrar.indexOf(id);
      this.borrar.splice(indice, 1);
      console.log(this.borrar);

    }
  }

  seleccionarTodo18() {
    this.todo18 = true;
    let valores = this.docs18.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar[i] = this.docs18[i].ID;
      console.log(this.borrar);

    }

  }

  quitarSeleccion18() {
    let valores = this.borrar.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar.pop();
      console.log(this.borrar);
      this.todo18 = false;
    }
  }

  checkCheckBoxvalue19(event: any, index: any, id: any) {
    console.log(event.target.checked)
    this.todo19 = null;
    this.valor = event.target.checked;
    if (this.valor === true) {
      console.log('verdadero');
      console.log(index);
      this.borrar[index] = this.docs19[index].ID;
      console.log(this.borrar);

    } else if (this.valor === false) {
      console.log('falso');
      console.log(index);
      let indice = this.borrar.indexOf(id);
      this.borrar.splice(indice, 1);
      console.log(this.borrar);

    }
  }

  seleccionarTodo19() {
    this.todo19 = true;
    let valores = this.docs19.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar[i] = this.docs19[i].ID;
      console.log(this.borrar);

    }

  }

  quitarSeleccion19() {
    let valores = this.borrar.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar.pop();
      console.log(this.borrar);
      this.todo19 = false;
    }
  }

  checkCheckBoxvalue20(event: any, index: any, id: any) {
    console.log(event.target.checked)
    this.todo20 = null;
    this.valor = event.target.checked;
    if (this.valor === true) {
      console.log('verdadero');
      console.log(index);
      this.borrar[index] = this.docs20[index].ID;
      console.log(this.borrar);

    } else if (this.valor === false) {
      console.log('falso');
      console.log(index);
      let indice = this.borrar.indexOf(id);
      this.borrar.splice(indice, 1);
      console.log(this.borrar);

    }
  }

  seleccionarTodo20() {
    this.todo20 = true;
    let valores = this.docs20.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar[i] = this.docs20[i].ID;
      console.log(this.borrar);

    }

  }

  quitarSeleccion20() {
    let valores = this.borrar.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar.pop();
      console.log(this.borrar);
      this.todo20 = false;
    }
  }
  checkCheckBoxvalue21(event: any, index: any, id: any) {
    console.log(event.target.checked)
    this.todo21 = null;
    this.valor = event.target.checked;
    if (this.valor === true) {
      console.log('verdadero');
      console.log(index);
      this.borrar[index] = this.docs21[index].ID;
      console.log(this.borrar);

    } else if (this.valor === false) {
      console.log('falso');
      console.log(index);
      let indice = this.borrar.indexOf(id);
      this.borrar.splice(indice, 1);
      console.log(this.borrar);

    }
  }

  seleccionarTodo21() {
    this.todo21 = true;
    let valores = this.docs21.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar[i] = this.docs21[i].ID;
      console.log(this.borrar);

    }

  }

  quitarSeleccion21() {
    let valores = this.borrar.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar.pop();
      console.log(this.borrar);
      this.todo21 = false;
    }
  }
  checkCheckBoxvalue22(event: any, index: any, id: any) {
    console.log(event.target.checked)
    this.todo22 = null;
    this.valor = event.target.checked;
    if (this.valor === true) {
      console.log('verdadero');
      console.log(index);
      this.borrar[index] = this.docs22[index].ID;
      console.log(this.borrar);

    } else if (this.valor === false) {
      console.log('falso');
      console.log(index);
      let indice = this.borrar.indexOf(id);
      this.borrar.splice(indice, 1);
      console.log(this.borrar);

    }
  }

  seleccionarTodo22() {
    this.todo22 = true;
    let valores = this.docs22.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar[i] = this.docs22[i].ID;
      console.log(this.borrar);

    }

  }
  quitarSeleccion22() {
    let valores = this.borrar.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar.pop();
      console.log(this.borrar);
      this.todo22 = false;
    }
  }

  checkCheckBoxvalue23(event: any, index: any, id: any) {
    console.log(event.target.checked)
    this.todo23 = null;
    this.valor = event.target.checked;
    if (this.valor === true) {
      console.log('verdadero');
      console.log(index);
      this.borrar[index] = this.docs23[index].ID;
      console.log(this.borrar);

    } else if (this.valor === false) {
      console.log('falso');
      console.log(index);
      let indice = this.borrar.indexOf(id);
      this.borrar.splice(indice, 1);
      console.log(this.borrar);

    }
  }

  seleccionarTodo23() {
    this.todo23 = true;
    let valores = this.docs23.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar[i] = this.docs23[i].ID;
      console.log(this.borrar);

    }

  }
  quitarSeleccion23() {
    let valores = this.borrar.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar.pop();
      console.log(this.borrar);
      this.todo23 = false;
    }
  }

  eliminarSeleccionados() {

    this.alerts.alertaConfirmacionEliminar('Eliminar documento', '¿Desea eliminar este documento?')
      .then((res: any) => {

        if (res.isConfirmed) {
          for (let numero of this.borrar) {
            console.log(numero);
            this.eliminarForm.ID = numero;
            this.eliminarForm.ESTATUS = 1;
            this.service.eliminarEnlace(this.eliminarForm).subscribe(
              (data: any) => {

              })
            this.alerts.alertaRealizado().then((res: any) => {
              location.reload()
            })

          }

        }

      })
  }

  eliminar(ID: any) {

    this.alerts.alertaConfirmacionEliminar('Eliminar documento', '¿Desea eliminar este documento?')
      .then((res: any) => {

        if (res.isConfirmed) {
          this.eliminarForm.ID = ID;
          this.eliminarForm.ESTATUS = 1;
          this.service.eliminarEnlace(this.eliminarForm).subscribe(
            (data: any) => {


              console.log(ID);

            })
          this.alerts.alertaRealizado().then((res: any) => {
            location.reload()
          })
        }

      })


  }


funcion1(){
  this.service.listaCimtraCarpeta(35,21).subscribe(res => {
    this.docs19 = res.body
    console.log(this.docs);

  })
}
funcion2(){
  this.service.listaCimtraCarpeta(35,22).subscribe(res => {
    this.docs20 = res.body
    console.log(this.docs);

  })
}

funcion3(){
  this.service.listaCimtraCarpeta(35,23).subscribe(res => {
    this.docs21= res.body
    console.log(this.docs);

  })
}


}
