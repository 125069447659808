<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <title>Autlan - Gobierno</title>
  <meta content="" name="description">
  <meta content="" name="keywords">

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
    rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">

  <!-- Template Main CSS File -->
  <link href="/assets/css/style.css" rel="stylesheet">

  <!-- =======================================================
  * Template Name: Company - v4.7.0
  * Template URL: https://bootstrapmade.com/company-free-html-bootstrap-template/
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  ======================================================== -->
</head>

<body>






  <section style="margin-top: 10vh;">

    <div class="div" style="text-align: center; justify-content: center;">
      <h2 style="color: rgb(190, 14, 14);"> Transparencia Autlán de Navarro</h2>
      <h5
        style="font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;margin-top: 2vh;">
        Bienvenido al Portal de Transparencia del H. Ayuntamiento de Autlán de Navarro</h5>
    </div>


    <div class="fondo"
      style="width: 60%;margin-left: 20%;margin-right: 20%; box-shadow:0 2px 4px #000000 ;border-radius: 2vh; ">

      <div class="contenido" style="margin-left: 10%;width: 80%;margin-right: 10%; margin-top: 5VH;">




        <div style="margin-top: 2vh;margin-left: 2vh;text-align: center;">
    <strong style="text-align: justify;margin-left: 1vh; color: rgb(77, 77, 77);margin-top: 2vh;font-size: 3vh;"> 1. Información sobre los gastos de   comunicación social</strong>
        </div>






      </div>







<!-- gastos de comunicacion 2024 -->

      <div class="row">

<div class="row">

<div class="col-2"></div>

<div class="col-8">

    <img style="border-radius: 2vh;margin-top: 5vh;" [src]="this.clima[0].URL" alt="">

</div>

<div class="col-2"></div>

</div>

<!-- termina -->

<!--
2023 -->


<div class="row">

  <div class="col-2"></div>

  <div class="col-8">

      <img style="border-radius: 2vh;margin-top: 5vh;" [src]="this.clima[1].URL" alt="">

  </div>

  <div class="col-2"></div>

  </div>

<!-- termina -->




<!-- comparativo -->
<div class="row">

  <div class="col-2"></div>

  <div class="col-8">


      <img style="border-radius: 2vh;margin-top: 5vh;" [src]="this.clima[2].URL" alt="">

  </div>

  <div class="col-2"></div>

  </div>

<!-- termina -->


<section *ngIf="this.token==true">

  <div class="row" >

    <div class="col-4">

      <form [formGroup]="imag1" (ngSubmit)="img1()" action="">
        <label style="margin-top: 2%; margin-right: 1%;font-weight: bold;">Editar gasto 2024:</label>
        <br><input type="file" style="margin-top: 1%;" (change)="changeFileMenu($event)">

        <i class="bi bi-check-circle-fill" style="font-size: 30px; color: #00B59C;"
          *ngIf="!subiendoDocumento && urlDocumento !== '' "></i>

        <div class="spinner-border text-primary" role="status" *ngIf="subiendoDocumento"></div>
        <div style="justify-content: center;text-align: center;" *ngIf="this.token===true">
          <button  type="submit" style="margin-top: 2vh;width: 25vh; border-radius: 1vh;"> Enviar cambio </button>

        </div>

        </form>



    </div>



    <div class="col-4">

      <form [formGroup]="imag2" (ngSubmit)="img2()" action="">
        <label style="margin-top: 2%; margin-right: 1%;font-weight: bold;">Editar gasto año pasado:</label>
        <br><input type="file" style="margin-top: 1%;" (change)="changeFileMenu1($event)">

        <i class="bi bi-check-circle-fill" style="font-size: 30px; color: #00B59C;"
          *ngIf="!subiendoDocumento1 && urlDocumento1 !== '' "></i>

        <div class="spinner-border text-primary" role="status" *ngIf="subiendoDocumento1"></div>
        <div style="justify-content: center;text-align: center;" *ngIf="this.token===true">
          <button  type="submit" style="margin-top: 2vh;width: 25vh; border-radius: 1vh;"> Enviar cambio </button>

        </div>

        </form>

    </div>


    <div class="col-4">


      <form [formGroup]="imag3" (ngSubmit)="img3()" action="">
        <label style="margin-top: 2%; margin-right: 1%;font-weight: bold;">Editar comparativo:</label>
        <br><input type="file" style="margin-top: 1%;" (change)="changeFileMenu2($event)">

        <i class="bi bi-check-circle-fill" style="font-size: 30px; color: #00B59C;"
          *ngIf="!subiendoDocumento2 && urlDocumento2 !== '' "></i>

        <div class="spinner-border text-primary" role="status" *ngIf="subiendoDocumento2"></div>
        <div style="justify-content: center;text-align: center;" *ngIf="this.token===true">
          <button  type="submit" style="margin-top: 2vh;width: 25vh; border-radius: 1vh;"> Enviar cambio </button>

        </div>

        </form>

    </div>
  </div>



</section>




      </div>



      <div class="row" style="margin-top: 15vh;">


        <div class="fondo" data-aos="fade-up">

          <div id="scroll">








            <div class="panel panel-default">
              <div class="panel-heading">
                <h4 class="panel-title">
                  <button (click)="funcion1()" data-bs-toggle="collapse" data-bs-parent="#accordion" href="#collapse2"
                    style="background: none; border: none;">
                    <i class="bi bi-folder-fill"> 2022</i></button>
                </h4>
              </div>
              <div id="collapse2" class="panel-collapse collapse in" data-bs-parent="#accordion">
                <div class="panel-body">
                  <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                    *ngIf="boton === 1">
                    <button style="height: 38px;" (click)="seleccionarTodo19()" type="button"
                      class="btn btn-outline-dark">Seleccionar Todo</button>
                    <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion19()" type="button"
                      class="btn btn-outline-dark">Desahacer Selección</button>
                    <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()" type="button"
                      class="btn btn-outline-dark">Eliminar Seleccionados</button>
                  </div>
                  <div class="fondo">

                    <div id="scroll">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col"> Nombre del
                              documento</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr *ngFor="let dato of docs19, let i = index">
                            <td><input type="checkbox" (change)="checkCheckBoxvalue19($event, i, dato?.ID)"
                                *ngIf="boton === 1" [checked]="todo19">

                              <a [href]="dato?.ARCHIVO" target="_blank" style="text-align: end;"> {{dato?.NOMBRE}}</a>
                            </td>
                            <td>
                              <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                class="btn btn-outline-dark">Eliminar</button>
                            </td>

                          </tr>

                        </tbody>
                      </table>

                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div class="panel panel-default">
              <div class="panel-heading">
                <h4 class="panel-title">
                  <button (click)="funcion2()" data-bs-toggle="collapse" data-bs-parent="#accordion" href="#collapse3"
                    style="background: none; border: none;">
                    <i class="bi bi-folder-fill"> 2023</i></button>
                </h4>
              </div>
              <div id="collapse3" class="panel-collapse collapse in" data-bs-parent="#accordion">
                <div class="panel-body">
                  <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                    *ngIf="boton === 1">
                    <button style="height: 38px;" (click)="seleccionarTodo20()" type="button"
                      class="btn btn-outline-dark">Seleccionar Todo</button>
                    <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion20()" type="button"
                      class="btn btn-outline-dark">Desahacer Selección</button>
                    <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()" type="button"
                      class="btn btn-outline-dark">Eliminar Seleccionados</button>
                  </div>
                  <div class="fondo">

                    <div id="scroll">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col"> Nombre del
                              documento</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr *ngFor="let dato of docs20, let i = index">
                            <td><input type="checkbox" (change)="checkCheckBoxvalue20($event, i, dato?.ID)"
                                *ngIf="boton === 1" [checked]="todo20">

                              <a [href]="dato?.ARCHIVO" target="_blank" style="text-align: end;">{{dato?.NOMBRE}}</a>
                            </td>
                            <td>
                              <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                class="btn btn-outline-dark">Eliminar</button>
                            </td>

                          </tr>
                        </tbody>
                      </table>

                    </div>
                  </div>
                </div>
              </div>
            </div>



            <div class="panel panel-default">
              <div class="panel-heading">
                <h4 class="panel-title">
                  <button (click)="funcion3()" data-bs-toggle="collapse" data-bs-parent="#accordion" href="#collapse3s"
                    style="background: none; border: none;">
                    <i class="bi bi-folder-fill"> 2024</i></button>
                </h4>
              </div>
              <div id="collapse3s" class="panel-collapse collapse in" data-bs-parent="#accordion">
                <div class="panel-body">
                  <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;"
                    *ngIf="boton === 1">
                    <button style="height: 38px;" (click)="seleccionarTodo21()" type="button"
                      class="btn btn-outline-dark">Seleccionar Todo</button>
                    <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion21()" type="button"
                      class="btn btn-outline-dark">Desahacer Selección</button>
                    <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()" type="button"
                      class="btn btn-outline-dark">Eliminar Seleccionados</button>
                  </div>
                  <div class="fondo">

                    <div id="scroll">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col"> Nombre del
                              documento</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr *ngFor="let dato of docs21, let i = index">
                            <td><input type="checkbox" (change)="checkCheckBoxvalue21($event, i, dato?.ID)"
                                *ngIf="boton === 1" [checked]="todo20">

                              <a [href]="dato?.ARCHIVO" target="_blank" style="text-align: end;">{{dato?.NOMBRE}}</a>
                            </td>
                            <td>
                              <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                class="btn btn-outline-dark">Eliminar</button>
                            </td>

                          </tr>
                        </tbody>
                      </table>

                    </div>
                  </div>
                </div>
              </div>
            </div>








          </div>
        </div>

      </div>



    </div>







  </section><!-- End Hero -->


  <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i
      class="bi bi-arrow-up-short"></i></a>

  <!-- Vendor JS Files -->
  <script src="/assets/vendor/aos/aos.js"></script>
  <script src="/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="/assets/vendor/glightbox/js/glightbox.min.js"></script>
  <script src="/assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
  <script src="/assets/vendor/swiper/swiper-bundle.min.js"></script>
  <script src="/assets/vendor/waypoints/noframework.waypoints.js"></script>
  <script src="/assets/vendor/php-email-form/validate.js"></script>

  <!-- Template Main JS File -->
  <script src="/assets/js/main.js"></script>

</body>

</html>
