import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { GeneralService } from 'src/services/general.service';
import { SweetAlertService } from 'src/services/sweet-alert.service';
import { Storage, ref, uploadBytes, getDownloadURL } from '@angular/fire/storage';
@Component({
  selector: 'app-subir',
  templateUrl: './subir.component.html',
  styleUrls: ['./subir.component.scss']
})
export class SubirComponent {
  articulo: any;
  nombre: any;

  documentoForm = new FormGroup({
    NOMBRE: new FormControl(''),
    ARCHIVO: new FormControl(''),
    SECCION: new FormControl(''),
    CARPETA: new FormControl(''),
    ESTATUS: new FormControl('')
  })

  //Variables para url de archivos
  urlDocumento = '';

  //Variables para indicar carga de archivos
  subiendoDocumento = false;

  archivos: string[] = [];

  constructor(private api: GeneralService, private alerts: SweetAlertService, private storage: Storage) { }

  ngOnInit(): void {
    this.articulo = localStorage.getItem('articulo');
    console.log(this.articulo);
    this.documentoForm.setValue({
      'NOMBRE': '',
      'ARCHIVO': '',
      'SECCION': '',
      'CARPETA': '0',
      'ESTATUS': '0'
    })

  }

  //** Promesa para obtener una clave de identificacion de documentos para subirlos a Firebase*/
  getIdentificadorDocumentos = new Promise((resolve, reject) => {
    this.api.obtenerIdentificadorDocumentos(1).subscribe(res => {
      resolve(res.body);  //Cuando se reciben los datos del servidor, resolvemos la promesa
    })
  })

  async contarArchivos(event: any) {
    let cont = event.target.files.length;
    console.log(cont);


    for (let i = 0; i < cont; i++) {
      console.log(i);
      this.changeFileMenu(event, i);
      let file = event.target.files[i];
      console.log(file.name);
      this.archivos[i] = file.name;
    }

    console.log(this.archivos);



  }


  async changeFileMenu(event: any, index: any) {

    let file = event.target.files[index];


    //TODO: Obtenemos nombre identificador para el documento
    this.getIdentificadorDocumentos.then(res => {
      let identificador = res;
      this.subiendoDocumento = true; //Indicamos que se comienza proceso subir foto
      //TODO: Subimos archivo a Firebase

      const name = '- Autlan';

      let path = 'Transparencia';  //Construimos ruta
      let fileRef = ref(this.storage, path + '/' + this.documentoForm.value.NOMBRE + identificador + this.archivos[index]); //Creamos una referncia al archivo usando la ruta

      //Subimos el archivo a Firebase
      uploadBytes(fileRef, file).then(response => {
        //TODO: Descargamos la URL del archivo
        getDownloadURL(fileRef).then(file => {
          this.urlDocumento = file; //guardamos la url del archivo en una variable
          this.subiendoDocumento = false;
          console.log(file);

          this.documentoForm.value.ARCHIVO = file;
          console.log(this.documentoForm.value.ARCHIVO);
          this.documentoForm.value.NOMBRE = this.archivos[index]
          console.log(this.documentoForm.value.NOMBRE);
          this.cargar();

        }).catch(error => { console.log(error) });

      })
    })

  }

  cargar() {

    console.log(this.documentoForm.value.NOMBRE);
    console.log(this.documentoForm.value);

    if (this.documentoForm.value.NOMBRE !== '' &&
    this.documentoForm.value.CARPETA !== '' &&
      this.documentoForm.value.ARCHIVO !== ''
     ) {
      console.log(this.documentoForm.value);
      this.api.nuevoCimtra(this.documentoForm.value).subscribe(res => {
        console.log('documento subido');
        this.alerts.alertaRealizadoAsistencia('Completado', 'El documento se ha cargado con exito');
      })
    } else if (this.documentoForm.value.NOMBRE === '' && this.documentoForm.value.ARCHIVO === '') {
      this.alerts.alertaError('Error', 'Llene los campos necesarios');
    } else if (this.documentoForm.value.NOMBRE === '') {
      this.alerts.alertaError('Error', 'Introduzca el nombre del documento');
    } else if (this.documentoForm.value.ARCHIVO === '') {
      this.alerts.alertaError('Error', 'Elija el documento a cargar');
    }


  }
}
