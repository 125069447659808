
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { CommonModule } from '@angular/common';
import { IndexComponent } from '../index/index.component';

import { LoginComponent } from '../LOGIN/login/login.component';
import { ComunicacionComponent } from '../1comunicacion/comunicacion.component';
import { FuncionariosMunicipalesComponent } from '../2funcionarios-municipales/funcionarios-municipales.component';
import { CuerpoEdilicioComponent } from '../3cuerpo-edilicio/cuerpo-edilicio.component';
import { GestionCuerpoComponent } from '../4gestion-cuerpo/gestion-cuerpo.component';
import { AsesoriasComponent } from '../5asesorias/asesorias.component';
import { GastoMunicipalComponent } from '../6gasto-municipal/gasto-municipal.component';
import { ProvedoresComponent } from '../7provedores/provedores.component';
import { AsignacionComponent } from '../8asignacion/asignacion.component';
import { InvitacionComponent } from '../9invitacion/invitacion.component';
import { RealizadasComponent } from '../10realizadas/realizadas.component';
import { InmueblesComponent } from '../13inmuebles/inmuebles.component';
import { ArrendadosComponent } from '../14arrendados/arrendados.component';
import { PatrimoniablesComponent } from '../15Patrimoniales/patrimoniables.component';
import { MueblesComponent } from '../16muebles/muebles.component';
import { SeleccionComponent } from '../17seleccion/seleccion.component';
import { ProgramasComponent } from '../18programas/programas.component';
import { ContratacionComponent } from '../19contratacion/contratacion.component';
import { DonativosComponent } from '../20donativos/donativos.component';
import { SubsidioComponent } from '../21subsidio/subsidio.component';
import { ComodatosComponent } from '../22comodatos/comodatos.component';
import { OrdenJudicialComponent } from '../23orden-judicial/orden-judicial.component';
import { MedicionComponent } from '../24medicion/medicion.component';
import { SocialesComponent } from '../25sociales/sociales.component';
import { IngresosExtrasComponent } from '../26ingresos-extras/ingresos-extras.component';
import { PermisosOtorgadosComponent } from '../27permisos-otorgados/permisos-otorgados.component';
import { UsoSueloComponent } from '../28uso-suelo/uso-suelo.component';
import { ActaComponent } from '../29acta/acta.component';
import { ConvocatoriaComponent } from '../30convocatoria/convocatoria.component';
import { ConsejosComponent } from '../31consejos/consejos.component';
import { ReglamentosComponent } from '../32reglamentos/reglamentos.component';
import { IntegrantesCiudadanosComponent } from '../33integrantes-ciudadanos/integrantes-ciudadanos.component';
import { MinutasComponent } from '../34minutas/minutas.component';
import { SesionesComponent } from '../35sesiones/sesiones.component';
import { CabildoComponent } from '../36cabildo/cabildo.component';
import { OrganismoComponent } from '../37organismo/organismo.component';
import { ComisionComponent } from '../38comision/comision.component';
import { PublicasComponent } from '../39publicas/publicas.component';
import { SesionesCabildoComponent } from '../40sesiones-cabildo/sesiones-cabildo.component';
import { FuncionariosComponent } from '../41funcionarios/funcionarios.component';
import { ConsecionesComponent } from '../42conseciones/conseciones.component';
import { MecanismosComponent } from '../43mecanismos/mecanismos.component';
import { OficinasComponent } from '../44oficinas/oficinas.component';
import { InternetPoblacionComponent } from '../45internet-poblacion/internet-poblacion.component';
import { SubirComponent } from '../subir/subir.component';
import { CoplaComponent } from '../11copla/copla.component';
import { ObrasrealizadasComponent } from '../12obrasrealizadas/obrasrealizadas.component';







const routes: Routes = [
  /*{
    path: '',
    component: DashboardComponent,
  },*/
  {
    path: 'inicio',
    component: IndexComponent
  },

  {

    path: 'login',
    component: LoginComponent
  },
  {
    path: 'gastos-comunicacion',
    component: ComunicacionComponent
  },
  {
    path: 'funcionarios-municipales',
    component: FuncionariosMunicipalesComponent
  },
  {
    path: 'cuerpo-edilicio',
    component: CuerpoEdilicioComponent
  },
  {
    path: 'gestion-cuerpo-edilicio',
    component: GestionCuerpoComponent
  },
  {
    path: 'asesorias',
    component: AsesoriasComponent
  },
  {
    path: 'gasto-municipal',
    component: GastoMunicipalComponent
  },
  {
    path: 'provedores',
    component: ProvedoresComponent
  },
  {
    path: 'asignacion',
    component: AsignacionComponent
  },
  {
    path: 'invitacion',
    component: InvitacionComponent
  },
  {
    path: 'realizadas',
    component: RealizadasComponent
  },
  {
    path: 'inmuebles',
    component: InmueblesComponent
  },
  {
    path: 'arrendado',
    component: ArrendadosComponent
  },
  {
    path: 'patrimoniable',
    component: PatrimoniablesComponent
  },
  {
    path: 'muebles',
    component: MueblesComponent
  },
  {
    path: 'seleccion',
    component: SeleccionComponent
  },
  {
    path: 'proveedores',
    component: ProvedoresComponent
  },
  {
    path: 'programas',
    component: ProgramasComponent
  },
  {
    path: 'contrataciones',
    component: ContratacionComponent
  },
  {
    path: 'donativos',
    component: DonativosComponent
  },
  {
    path: 'subsidios',
    component: SubsidioComponent
  },
  {
    path: 'comodatos',
    component: ComodatosComponent
  },
  {
    path: 'orden-judicial',
    component: OrdenJudicialComponent
  },
  {
    path: 'medicion',
    component: MedicionComponent
  },
  {
    path: 'sociales',
    component: SocialesComponent
  },
  {
    path: 'ingresos',
    component: IngresosExtrasComponent
  },
  {
    path: 'uso-suelo',
    component: PermisosOtorgadosComponent
  },
  {
    path: 'permisos',
    component: UsoSueloComponent
  },
  {
    path: 'acta',
    component: ActaComponent
  },
  {
    path: 'convocatoria',
    component: ConvocatoriaComponent
  },
  {
    path: 'consejo',
    component: ConsejosComponent
  },
  {
    path: 'reglamentos',
    component: ReglamentosComponent
  },
  {
    path: 'integrantes-ciudadanos',
    component: IntegrantesCiudadanosComponent
  },
  {
    path: 'minutas',
    component: MinutasComponent
  },
  {
    path: 'sasiones',
    component: SesionesComponent
  },
  {
    path: 'cabildo',
    component: CabildoComponent
  },
  {
    path: 'organismos',
    component: OrganismoComponent
  },
  {
    path: 'comision',
    component: ComisionComponent
  },
  {
    path: 'publicas',
    component: PublicasComponent
  },
  {
    path: 'sesiones-cabildo',
    component: SesionesCabildoComponent
  },
  {
    path: 'funcionarios',
    component: FuncionariosComponent
  },
  {
    path: 'conseciones',
    component: ConsecionesComponent
  },
  {
    path: 'mecanismos',
    component: MecanismosComponent
  },
  {
    path: 'copla',
    component: CoplaComponent
  },
  {
    path: 'obras-realizadas',
    component: ObrasrealizadasComponent
  },
  {
    path: 'oficinas',
    component: OficinasComponent
  },
  {
    path: 'internet-poblacion',
    component: InternetPoblacionComponent
  },
  {
    path: 'cargar',
    component: SubirComponent
  }

];

@NgModule({
  imports: [CommonModule, RouterModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
})

export class DashboardRoutingModule { }
