<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <title>Autlan - Gobierno</title>
  <meta content="" name="description">
  <meta content="" name="keywords">

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
    rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">

  <!-- Template Main CSS File -->
  <link href="/assets/css/style.css" rel="stylesheet">

  <!-- =======================================================
  * Template Name: Company - v4.7.0
  * Template URL: https://bootstrapmade.com/company-free-html-bootstrap-template/
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  ======================================================== -->
</head>

<body>






  <section style="margin-top: 10vh;">

    <div class="div" style="text-align: center; justify-content: center;">
      <h2 style="color: rgb(190, 14, 14);"> Transparencia Autlán de Navarro</h2>
      <h5
        style="font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;">
        Bienvenido al Portal de Transparencia del H. Ayuntamiento de Autlán de Navarro</h5>
    </div>


    <div class="fondo"
      style="width: 60%;margin-left: 20%;margin-right: 20%; box-shadow:0 2px 4px #000000 ;border-radius: 2vh; ">












          <div class="row">

            <div class="col-2"></div>

            <div class="col-8">

                <img width="100%" style="border-radius: 2vh;margin-top: 5vh;" src="https://i.imgur.com/amSVpI0.png" alt="">

            </div>

            <div class="col-2"></div>

            </div>


            <div style="margin-top: 2vh;margin-left: 2vh;text-align: center;">
              <strong style="text-align: justify; color: rgb(77, 77, 77);margin-top: 2vh;font-size: 2vh;"> El gobierno municipal tiene a la vista de toda persona en formato abierto, accesible y electrónico información sobre si el gobierno municipal tiene un organismo ciudadano
                (comités de vigilancia, contraloría social u otro organismo ciudadano) , reconocido por el gobierno municipal, encargado de revisar,supervisar y evaluar la ejecución de obra pública,
                programas de asistencia y desarrollo social en el municipio</strong>
                  </div>








      <div class="row">


        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
          <button style="height: 38px;" (click)="seleccionarTodo()" type="button" class="btn btn-outline-dark">Seleccionar
            Todo</button>
          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion()" type="button"
            class="btn btn-outline-dark">Desahacer Selección</button>
          <button style="height: 38px; margin-left: 10px;" (click)="eliminarSeleccionados()" type="button"
            class="btn btn-outline-dark">Eliminar Seleccionados</button>
        </div>
        <div class="fondo" data-aos="fade-up">

          <div id="scroll">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col"> Nombre del documento</th>

                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let dato of docs, let i = index">
                  <td><input type="checkbox" (change)="checkCheckBoxvalue($event, i, dato?.ID)" *ngIf="boton === 1"
                      [checked]="todo">

                    <a [href]="dato?.ARCHIVO" target="_blank" style="text-align: end;">{{dato?.NOMBRE}} </a>

                  </td>
                  <td>
                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                      class="btn btn-outline-dark">Eliminar</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>







    </div>







  </section><!-- End Hero -->


  <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i
      class="bi bi-arrow-up-short"></i></a>

  <!-- Vendor JS Files -->
  <script src="/assets/vendor/aos/aos.js"></script>
  <script src="/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="/assets/vendor/glightbox/js/glightbox.min.js"></script>
  <script src="/assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
  <script src="/assets/vendor/swiper/swiper-bundle.min.js"></script>
  <script src="/assets/vendor/waypoints/noframework.waypoints.js"></script>
  <script src="/assets/vendor/php-email-form/validate.js"></script>

  <!-- Template Main JS File -->
  <script src="/assets/js/main.js"></script>

</body>

</html>
