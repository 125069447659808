<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <title>Autlan - Gobierno</title>
  <meta content="" name="description">
  <meta content="" name="keywords">

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
    rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">

  <!-- Template Main CSS File -->
  <link href="/assets/css/style.css" rel="stylesheet">

  <!-- =======================================================
  * Template Name: Company - v4.7.0
  * Template URL: https://bootstrapmade.com/company-free-html-bootstrap-template/
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  ======================================================== -->
</head>

<body>






  <section style="margin-top: 10vh;">

    <div class="div" style="text-align: center; justify-content: center;">
      <h2 style="color: rgb(14, 73, 190);"> Transparencia Autlán de Navarro</h2>
      <h5
        style="font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;margin-top: 5vh;">
        Bienvenido al Portal de Transparencia del H. Ayuntamiento de Autlán de Navarro</h5>
    </div>


    <div class="fondo"
      style="width: 60%;margin-left: 20%;margin-right: 20%; box-shadow:0 2px 4px #000000 ;border-radius: 1vh; ">

      <div class="contenido" style="margin-left: 10%;width: 80%;margin-right: 10%; margin-top: 5VH;">






        <div class="row" style="margin-top: 2vh;">



          <div class="col-12">
            <img class="img" width="500"  src="https://i.imgur.com/amSVpI0.png" alt="">
          </div>
        </div>







      </div>





      <div style="width: 80%; margin-left: 10%; margin-right: 10%; margin-top: 3vh;" class="Sección">

        <div class=""
          style="border: solid; border-color: rgb(148, 148, 148);border-top-width: 2vh; border-radius: 1vh;text-align: justify;">

          <div class="row">

            <div class="col-4">
              <h2  style="font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;margin-left: 2VH;" > Sección de gastos.
              </h2>
            </div>
            <div class="col-6">
            </div>
          </div>

          <div style="width: 90%; margin-left: 5%; margin-right: 5%;">


            <strong style="text-align: justify;margin-left: 1vh;"> 1. Información sobre los gastos de   <a href="admin/gastos-comunicacion" style="color: rgb(8, 121, 235);;"> comunicación social</a>
             </strong> <br>
            <strong style="text-align: justify;margin-left: 1vh;"> 2. Gastos por representaciones o viáticos de los
               <a href="admin/funcionarios-municipales" style="color: rgb(8, 121, 235);">  funcionarios municipales de primer nivel </a>  </strong> <br>

            <strong style="text-align: justify;margin-left: 1vh;"> 3. Gastos por representaciones o viáticos del <a href="admin/cuerpo-edilicio" style="color: rgb(8, 121, 235);;">  cuerpo
              edilicio  </a> </strong> <br>

            <strong style="text-align: justify;margin-left: 1vh;"> 4. Gastos de gestión de su <a href="admin/gestion-cuerpo-edilicio" style="color: rgb(8, 121, 235);;">  cuerpo
              edilicio  </a> </strong><br>

            <strong style="text-align: justify;margin-left: 1vh;"> 5. Gasto realizado por concepto de pago de  <a href="admin/asesorias" style="color: rgb(8, 121, 235);;">  asesorías
              al gobierno municipal  </a> </strong> <br>
            <strong style="text-align: justify;margin-left: 1vh;"> 6. Ejercicios de  <a href="admin/gasto-municipal" style="color: rgb(8, 121, 235);;"> gasto municipal   </a> </strong> <br>

            <strong style="text-align: justify;margin-left: 1vh;"> 7. Información sobre  <a href="admin/provedores" style="color: rgb(8, 121, 235);;">  Proveedores  </a> </strong>


          </div>


        </div>
      </div>


      <div style="width: 80%; margin-left: 10%; margin-right: 10%; margin-top: 3vh;" class="Sección">

        <div
          style="border: solid; border-color: rgb(148, 148, 148);;border-top-width: 2vh; border-radius: 1vh;text-align: justify;">

          <div class="row">

            <div class="col-4">
              <h2 style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;margin-left: 2vh;"> Sección de Obras.</h2>
            </div>
            <div class="col-6">
            </div>
          </div>
          <strong style="text-align: justify;" for="">

            <div style="width: 90%; margin-left: 5%; margin-right: 5%;">

              <strong> 8. Listado de los contratos de  <a href="admin/asignacion" style="color: rgb(8, 121, 235);;">obras y servicios por asignación directa</a> a un  proveedor
                determinado<br>
                9. Listado de los contratos de
                <a href="admin/invitacion" style="color: rgb(8, 121, 235);;"> obras y servicios otorgados por invitación restringida y/o cerrada </a>a un grupo de proveedores<br>
                10. Listado de las<a href="admin/realizadas" style="color: rgb(8, 121, 235);;"> obras realizadas en los últimos tres años</a>  <br>
                11. Listado con las <a href="admin/copla" style="color: rgb(8, 121, 235);;"> propuestas de obra del COPLADEMUN</a> planteadas al Ayuntamiento para el ejercicio del
                Ramo 33 del año en curso  <br>
                12. Información sobre <a href="admin/obras-realizadas"  style="color: rgb(8, 121, 235);;">obras realizadas a través del Ramo 33</a>  </strong>

            </div>



          </strong>
        </div>
      </div>




      <div style="width: 80%; margin-left: 10%; margin-right: 10%; margin-top: 3vh;" class="Sección">

        <div
          style="border: solid; border-color: rgb(148, 148, 148);;border-top-width: 2vh; border-radius: 1vh;text-align: justify;">

          <div class="row">

            <div class="col-6">
              <h2 style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;margin-left: 2vh;"> Sección sobre bienes y
                usos.</h2>
            </div>
            <div class="col-6">
            </div>
          </div>
          <div style="width: 90%; margin-left: 5%; margin-right: 5%;">

            <strong style="text-align: justify;" for="">

              13. Información sobre los  <a href="admin/inmuebles" style="color: rgb(8, 121, 235);;"> bienes inmuebles que el municipio arrienda a personas físicas o morales</a> <br>
              14. Información sobre los  <a href="admin/arrendado" style="color: rgb(8, 121, 235);;">bienes inmuebles arrendados por el municipio</a>  <br>
              15. Información sobre  <a href="admin/patrimoniable" style="color: rgb(8, 121, 235);;">bienes  inmuebles patrimoniales propiedad</a> <br>
              16. Información sobre  <a href="admin/muebles" style="color: rgb(8, 121, 235);;">bienes muebles patrimoniales propiedad</a> <br>


            </strong>

          </div>
        </div>
      </div>




      <div style="width: 80%; margin-left: 10%; margin-right: 10%; margin-top: 3vh;" class="Sección">

        <div
          style="border: solid; border-color: rgb(148, 148, 148);border-top-width: 2vh; border-radius: 1vh;text-align: justify;">

          <div class="row">

            <div class="col-6">
              <h2 style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;margin-left: 2vh;"> Sección de
                administración.</h2>
            </div>
            <div class="col-4">
            </div>
          </div>


          <div style="width: 90%; margin-left: 5%; margin-right: 5%;">
            <strong style="text-align: justify;" for="">

              17.Información sobre el  <a href="admin/seleccion" style="color: rgb(8, 121, 235);;">método de selección y la lista de los integrantes del comité</a>  (cualquiera que sea
              su nombre) que vigila/revisa las licitaciones de obras, las compras, contrataciones, arrendamientos y
              contratos de servicios que el Ayuntamiento realiza,
              además de designar al o los ganadores.<br> <br>

              18. Información sobre todos los  <a href="admin/programas" style="color: rgb(8, 121, 235);;">programas sociales prevalecientes en el municipio</a> <br><br>

              19. Información sobre el procedimiento para  la  <a href="admin/contrataciones" style="color: rgb(8, 121, 235);;">selección, contratación y despido</a>  de cualquier empleado o
              funcionario municipal (desde primer nivel), ya sea de base, sindicalizado, por honorarios o de confianza.
              <br><br>

              20. Información sobre todos  <a href="admin/donativos" style="color: rgb(8, 121, 235);;"> los donativos (en especie o económicos)</a> otorgados por el Presidente Municipal,
              cualquier funcionario o el Ayuntamiento a cualquier persona física o moral (sindicatos, asociaciones
              civiles, deportivos, centros culturales, etc.). <br><br>

              21. Información sobre todos los programas de  <a href="admin/subsidios" style="color: rgb(8, 121, 235);;">subsidio (en especie o financiero) </a> otorgados por el
              Presidente municipal, cualquier funcionario o el Ayuntamiento a cualquier persona física u organismo
              público o privado<br><br>

              22. Información sobre todos los  <a href="admin/comodatos" style="color: rgb(8, 121, 235);;">donativos (en especie o económicos) o comodatos otorgados</a>  al Presidente
              Municipal, a funcionario alguno, regidores y síndico o al Ayuntamiento por cualquier persona física o
              moral (sindicatos, asociaciones civiles,
              deportivos, centros culturales, etc.).<br><br>

              23. Información sobre los listados de los  <a href="admin/orden-judicial" style="color: rgb(8, 121, 235);;">mandatos por orden judicial</a>  que obligan al Municipio otorgar
              licencias, permisos y ejecutar laudos laborales<br><br>


              24. Información sobre el listad <a href="https://transparencia.autlan.gob.mx/admin/articulo-8/fraccion-IV/in-h" style="color: rgb(8, 121, 235);;">o de indicadores de su sistema de medición y/o evaluación y sus resultados</a>


<br><br>

              25. Monto de los  <a href="admin/sociales" style="color: rgb(8, 121, 235);;">subejercicios de cada uno de los programas sociales, tanto federal, estatal y municipal</a>
              así como el destino de estos recursos.<br><br>

              26.  <a href="https://transparencia.autlan.gob.mx/admin/articulo-8/fraccion-V/in-b" style="color: rgb(8, 121, 235);;">Ingresos extras (originalmente no presupuestados)</a> que recibe el municipio tanto de la federación como
              del estado, así como el destino, utilización y comprobación de ese recurso (cuentas y programas).<br><br>

            </strong>

          </div>
        </div>
      </div>




      <div style="width: 80%; margin-left: 10%; margin-right: 10%; margin-top: 3vh;" class="Sección">

        <div
          style="border: solid; border-color: rgb(148, 148, 148);border-top-width: 2vh; border-radius: 1vh;text-align: justify;">

          <div class="row">

            <div class="col-8">
              <h2 style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;margin-left: 2vh;"> Sección de Urbanidad.
              </h2>
            </div>
            <div class="col-6">
            </div>
          </div>

          <div style="width: 90%; margin-left: 5%; margin-right: 5%;">
            <strong style="text-align: justify;" for="">


              27. Información sobre los <a href="admin/uso-suelo" style="color: rgb(8, 121, 235);;">cambios de uso de suelo </a>  que se han autorizado en la presente administración<br>
              28.Información sobre un listado de <a href="admin/permisos" style="color: rgb(8, 121, 235);;">permisos otorgados para la construcción de fraccionamientos </a> nuevos que
              se han autorizado en la presente administración <br>



            </strong>
          </div>
        </div>
      </div>


      <div style="width: 80%; margin-left: 10%; margin-right: 10%; margin-top: 3vh;" class="Sección">

        <div
          style="border: solid; border-color: rgb(148, 148, 148);border-top-width: 2vh; border-radius: 1vh;text-align: justify;">

          <div class="row">

            <div class="col-6">
              <h2 style="font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;margin-left: 2vh;"> Sección sobre consejos.
              </h2>
            </div>
            <div class="col-4">
            </div>
          </div>

          <div style="width: 90%; margin-left: 5%; margin-right: 5%;">
            <strong style="text-align: justify;" for="">



29. Información sobre el <a href="admin/acta" style="color: rgb(8, 121, 235);;"> acta de instalación </a>de cada uno de los consejos/ Comités municipales<br><br>
30. Información sobre la realización de la <a href="admin/convocatoria" style="color: rgb(8, 121, 235);;">convocatoria pública para invitar y seleccionar a los integrantes ciudadanos </a>  del o los Consejos/Comités Municipales <br><br>
31. Información sobre que al menos el <a href="admin/consejo" style="color: rgb(8, 121, 235);;"> 51% de los miembros de los Consejos/Comités proceden de la sociedad civil</a> (no funcionario público de algún orden de gobierno u organismo público) <br><br>
32. Información sobre la existencia de <a href="admin/reglamentos" style="color: rgb(8, 121, 235);;">reglamento(s) de formación y/o de operación</a>  aprobados por el Cabildo para el o los Consejos/Comités Municipales e indican las reglas específicas para elegir a sus miembros <br><br>
33. Información sobre el o los reglamentos de los Consejos/Comités Municipales establece que la <a href="admin/integrantes-ciudadanos" style="color: rgb(8, 121, 235);;">selección de los nuevos integrantes ciudadanos </a>  debe realizarse a mitad del periodo de gobierno o de forma escalonada o que el cambio no se dé al inicio de la Administración.
Lo anterior para garantizar y/o asegurar la continuidad de los trabajos <br><br>
34. Información sobre si son públicas (en versión impresa o electrónica) el <a href="admin/minutas" style="color: rgb(8, 121, 235);;">total de actas o  minutas</a>  de las reuniones del o los Consejos/Comités Municipales celebradas desde el inicio de la presente administración <br><br>
35. Información sobre si se establece en los reglamentos del o los Consejos/Comités Municipales que las <a href="admin/sasiones" style="color: rgb(8, 121, 235);;">sesiones sean públicas</a>  (con acceso para el ciudadano)



            </strong>
          </div>
        </div>
      </div>

      <div style="width: 80%; margin-left: 10%; margin-right: 10%; margin-top: 3vh;" class="Sección">

        <div
          style="border: solid; border-color: rgb(148, 148, 148);border-top-width: 2vh; border-radius: 1vh;text-align: justify;">

          <div class="row" >

            <div class="col-8">
              <h2 style="font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;margin-left: 2vh;"> Sección de participación ciudadana.
              </h2>
            </div>
            <div class="col-2">
            </div>
          </div>

          <div style="width: 90%; margin-left: 5%; margin-right: 5%;">
            <strong style="text-align: justify;" for="">




              36. Información sobre si existe un <a href="admin/cabildo" style="color: rgb(8, 121, 235);;"> reglamento aprobado por Cabildo de participación ciudadana/participación vecinal</a><br><br>
              37. El gobierno municipal tiene a la vista de toda persona en formato abierto, accesible y electrónico información sobre si el gobierno municipal tiene un <a href="admin/organismos" style="color: rgb(8, 121, 235);;">
                organismo ciudadano (comités de vigilancia, contraloría social u otro organismo ciudadano)</a>
              , reconocido por el
              gobierno municipal, encargado de revisar,supervisar y evaluar la ejecución de obra pública, programas de asistencia y desarrollo social en el municipio <br>




            </strong>
          </div>
        </div>
      </div>




      <div style="width: 80%; margin-left: 10%; margin-right: 10%; margin-top: 3vh;" class="Sección">

        <div
          style="border: solid; border-color: rgb(148, 148, 148);border-top-width: 2vh; border-radius: 1vh;text-align: justify;">

          <div class="row">

            <div class="col-6">
              <h2 style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;margin-left: 2vh;"> Sección de cabildo.
              </h2>
            </div>
            <div class="col-4">
            </div>
          </div>

          <div style="width: 90%; margin-left: 5%; margin-right: 5%;">
            <strong style="text-align: justify;" for="">





38. Información sobre el listado de Comisiones de Ayuntamiento, programa de trabajo, un informe anual de resultados y actas de reunión de cada <a href="admin/comision" style="color: rgb(8, 121, 235);;">Comisión del Ayuntamiento</a> <br><br>
39. Información sobre si se señala en algún Reglamento Municipal o la Ley Estatal sobre el Municipio que las sesiones de Cabildo sean<a href="admin/publicas" style="color: rgb(8, 121, 235);;">públicas y abiertas</a>  <br><br>
40. Información sobre las <a href="https://transparencia.autlan.gob.mx/admin/articulo-8/fraccion-VI/in/j" style="color: rgb(8, 121, 235);;"> Sesiones de Cabildo</a><br><br>
41.Información sobre <a href="admin/funcionarios" style="color: rgb(8, 121, 235);;"> cada integrante del Ayuntamiento y de los funcionarios de primer nivel</a> <br><br>




            </strong>
          </div>
        </div>
      </div>

      <div style="width: 80%; margin-left: 10%; margin-right: 10%; margin-top: 3vh;" class="Sección">

        <div
          style="border: solid; border-color: rgb(148, 148, 148);border-top-width: 2vh; border-radius: 1vh;text-align: justify;">

          <div class="row">

            <div class="col-8">
              <h2 style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;margin-left: 2vh;"> Sección de atención ciudadana.
              </h2>
            </div>
            <div class="col-4">
            </div>
          </div>

          <div style="width: 90%; margin-left: 5%; margin-right: 5%; margin-bottom: 10vh;">
            <strong style="text-align: justify;" for="">






              42. Información (impresa o electrónica) sobre de los requisitos, costos y tiempos de respuesta para los usuarios al menos de los siguientes <a href="admin/conseciones" style="color: rgb(8, 121, 235);;">servicios o concesiones municipales</a> <br><br>
              43. Información sobre si existen los siguientes <a href="https://transparencia.autlan.gob.mx/admin/articulo-8/f8/fraccion-VIII" style="color: rgb(8, 121, 235);;"> mecanismos de atención ciudadana</a><br><br>
              44. El gobierno municipal tiene a la vista de toda persona en formato abierto, accesible y electrónico información sobre si existe <a href="admin/oficinas" style="color: rgb(8, 121, 235);;"> oficina, módulo o mecanismo de acceso a la información pública</a><br><br>
              45. Información sobre si el gobierno municipal <a href="https://autlan.gob.mx/admin/sesiones" style="color: rgb(8, 121, 235);;">hace uso del Internet para informar a su población de manera adecuada</a>  <br><br>




            </strong>
          </div>
        </div>
      </div>







    </div>







  </section><!-- End Hero -->


  <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i
      class="bi bi-arrow-up-short"></i></a>

  <!-- Vendor JS Files -->
  <script src="/assets/vendor/aos/aos.js"></script>
  <script src="/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="/assets/vendor/glightbox/js/glightbox.min.js"></script>
  <script src="/assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
  <script src="/assets/vendor/swiper/swiper-bundle.min.js"></script>
  <script src="/assets/vendor/waypoints/noframework.waypoints.js"></script>
  <script src="/assets/vendor/php-email-form/validate.js"></script>

  <!-- Template Main JS File -->
  <script src="/assets/js/main.js"></script>

</body>

</html>
